import { KeycloakService } from "keycloak-angular";

import { environment } from "../environments/environment";
import { initNewRelic } from "./initNewRelic";

export function initializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    initNewRelic();
    return new Promise(async (resolve, reject) => {
      try {
        await keycloak.init(environment.keycloakInit);
        resolve(true);
      } catch (error) {
        const err: string = error?.error;
        if (err?.includes("Timeout when waiting for 3rd party check iframe message.")) {
          console.log(error);
          window.location.reload();
        } else {
          reject(error);
        }
      }
    });
  };
}
