import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateLoader } from "@ngx-translate/core";
import cacheBusting from "../../../assets/i18n/cache-busting.json";

@Injectable({ providedIn: "root" })
export class CustomTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    // o arquivo cacheBusting é substituido no deploy
    const ptCache = (cacheBusting as any)?.pt;
    const langCache = cacheBusting?.[lang];
    const version = ptCache + (langCache && langCache != ptCache ? `_${langCache}` : "");
    const path = `/assets/i18n/${lang}.json?v=${version ?? Date.now()}`;
    return this.http.get(path);
  }
}
