import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { KeycloakService } from "keycloak-angular";

import { LayoutComponent } from "../layout.component";
import { SidebarService } from "../sidebar/sidebar.service";
import { UsuarioDataService } from "src/app/shared/services/usuario-data.service";
import { TranslateService } from "@ngx-translate/core";
import { EditarDadosPopupComponent } from "../editar-dados-popup/editar-dados-popup.component";
import { Subscription } from "rxjs";

/**
 * Componente da barra superior do sistema.
 */
@Component({
  selector: "app-topbar",
  templateUrl: "topbar.component.html",
  styleUrls: ["./topbar.compent.scss"],
})
export class TopbarComponent implements OnInit, OnDestroy {
  nmCliente: string;
  nmUsuario: string;
  nmInicais: string;
  logomarca: string;
  sub: Subscription;

  constructor(
    public app: LayoutComponent,
    private keyclockService: KeycloakService,
    private sidebarService: SidebarService,
    private usuarioDataService: UsuarioDataService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    //Recuperar nome do cliente prontos
    const isEspanhol = this.translateService.getBrowserLang() === "es";
    this.logomarca = isEspanhol ? "assets/images/logo-listos-02.png" : "assets/images/prontos-icone-02.svg";
    this.nmCliente = this.usuarioDataService.getClientName();
    this.sub = this.usuarioDataService.nameFromApiObservable.subscribe((_) => {
      this.nmUsuario = this.usuarioDataService.getName();
      this.nmInicais = this.usuarioDataService.getInitials();
    });
  }

  toggleSidebar() {
    this.sidebarService.toggleSidebar();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
