import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AuthComponent } from './auth.component';

const modules = [
  SharedModule,
];

const components = [
  AuthComponent,
];

@NgModule({
  imports: [
    modules,
  ],
  declarations: [
    components,
  ],
  exports: [
    components,
  ],
})
export class AuthModule { }
